
    import { defineComponent, reactive, ref, toRefs, nextTick } from 'vue';
    import { useStore } from 'vuex';
    import moment from "moment";
    import Pagination from "@/components/Pagination.vue";
    import TableWrapper from "@/components/TableWrapper.vue";
    import ProgramPreview from "@/views/Approve/components/ProgramPreview.vue";
    import useFetch from "@/hooks/useFetch";
    import useTableHeight from "@/hooks/useTableHeight";
    import useTableRowHighlight from "@/hooks/approve/useTableRowHighlight";
    import {
        getSubscribedBu,
        getVehicleTypeClass,
        getProgramTracking,
        getProgramStatus
    } from "@/API/approve";
    import { BuItem, ProgramStatusItem, TypeClassItem } from "@/views/Approve/SalesRoleApprove/ProgramHome/types";
    import { ProgramTracking, ProgramTrackingQueryParams } from '../types';
    import { useRouter } from "vue-router";
    import WordingBudget from '@/views/Program/Wording/WordingBudget.vue'

    const columns = [
        {
            title: 'BU',
            dataIndex: 'bu',
            key: 'bu',
            width: 100
        },
        {
            title: 'Program Name',
            dataIndex: 'programName',
            key: 'programName',
            slots: { customRender: 'programName' },
            width: 240,
            ellipsis: true
        },
        {
            title: 'Program Code',
            dataIndex: 'programCode',
            key: 'programCode',
            width: 150,
            ellipsis: true
        },
        {
            title: 'Batch Name',
            dataIndex: 'batchName',
            key: 'batchName',
            slots: { customRender: 'batchName' },
            width: 240,
            ellipsis: true
        },
        {
            title: 'Make',
            dataIndex: 'make',
            key: 'make',
            width: 120
        },
        {
            title: 'Period from',
            dataIndex: 'periodFrom',
            key: 'periodFrom',
            slots: { customRender: 'periodFrom' },
            width: 120
        },
        {
            title: 'To',
            dataIndex: 'to',
            key: 'to',
            slots: { customRender: 'to' },
            width: 120
        },
        {
            title: 'Program status',
            dataIndex: 'programStatus',
            key: 'programStatus',
            width: 120
        },
        {
            title: 'Last update',
            dataIndex: 'lastUpdate',
            key: 'lastUpdate',
            slots: { customRender: 'lastUpdate' },
            width: 120
        },
        {
            title: 'Tracking',
            dataIndex: 'logDtoList',
            key: 'logDtoList',
            slots: { customRender: 'tracking' },
            width: 100,
            align: 'center'
        }
    ];

    export default defineComponent({
        name: "BatchTracking",
        components: { Pagination, TableWrapper, ProgramPreview, WordingBudget },
        setup() {
            const store = useStore();
            const router = useRouter();
            const visible = ref<boolean>(false);

            const programPreviewData = reactive<{ programId: string; programPeriod: string}>({
                programId: '',
                programPeriod: ''
            });

            const { tableHeight } = useTableHeight();
            const isOpen = ref(false)
            const handleCollapse = () => {
                isOpen.value = !isOpen.value
                nextTick(() => {
                    const tablePosition = useTableHeight(true, '.pagination-container', true);
                    tableHeight.value = tablePosition.tableHeight.value
                })
            }

            //Bu
            const { data: buOptions } = useFetch<BuItem[]>({}, [], getSubscribedBu);

            //typeClass
            const { data: typeClassOptions } = useFetch<TypeClassItem[]>({}, [], getVehicleTypeClass);

            //查询program status
            const { data: programStatusOptions } = useFetch<ProgramStatusItem[]>({}, [], getProgramStatus);

            const queryParams = reactive<ProgramTrackingQueryParams>({
                bu: [],
                programName: '',
                from: '',
                to: '',
                type: [],
                programStatus: '',
                programCode: ''
            });

            const { data, pagination, refreshFetch  } = useFetch<ProgramTracking[]>(queryParams, [], getProgramTracking, true);

            const { currentClickRowIndex, handlerRowClick } = useTableRowHighlight(visible);

            const filterOption = (input: string, option: any) => {
                return option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }

            //分页事件处理
            const handlerPaginationChange = (page: number): void => {
                pagination.page = page;
                refreshFetch();
            }

            const handlerShowSizeChange = (current: number, pageSize: number): void => {
                Object.assign(pagination, { page: 1, pageSize: pageSize });
                refreshFetch();
            }

            const handlerPeriodChange = (date: string[]): void => {
                queryParams.from = date[0];
                queryParams.to = date[1];
            }
             // woridng preview
            const visiblePreview = ref(false)
            const handlerClickProgramName = (record: ProgramTracking, index: number): void => {
                // visible.value = true;
                programPreviewData.programId = record.programId;
                programPreviewData.programPeriod = `${moment(record.periodFrom).format('YYYY.MM')}-${moment(record.to).format('YYYY.MM')}`;
                // router.push({
                //   path: "/wordingbudget",
                //   query: {
                //     programId: programPreviewData.programId,
                //     programPeriod: programPreviewData.programPeriod
                //   }
                // });
                const params = {
                    programId: programPreviewData.programId,
                    programPeriod: programPreviewData.programPeriod,
                    programName: `${record.programName}(${record.programCode})`
                }
                sessionStorage.setItem('previewPgmId', record.programId)
                visiblePreview.value = true
                // sessionStorage.setItem('paramsWording', JSON.stringify(params))
                // window.open(`${window.location.origin}/#/wordingbudget`)
                //点击高亮
                handlerRowClick(index);
            }

            const handlerResetClick = (): void => {
                Object.assign(queryParams, {
                    bu: [],
                    programName: '',
                    from: '',
                    to: '',
                    type: [],
                    programStatus: '',
                    programCode: ''
                })
                refreshFetch(1);
            }

            const handlerSearchClick = (): void => {
                refreshFetch(1);
            }

            return {
                columns,
                data,
                pagination,
                tableHeight,

                //wording
                visible,
                programPreviewData,

                //事件处理
                handlerPaginationChange,
                handlerShowSizeChange,
                handlerPeriodChange,
                handlerClickProgramName,
                handlerResetClick,
                handlerSearchClick,

                //queryParams
                ...toRefs(queryParams),

                //select options
                buOptions,
                typeClassOptions,
                programStatusOptions,

                //select
                filterOption,

                //moment
                moment,

                //store
                store,

                //高亮table row
                currentClickRowIndex,
                isOpen,
                handleCollapse,
                visiblePreview
            }
        }
    })
