
    import { defineComponent, reactive, toRefs, ref } from 'vue';
    import moment from "moment";
    import { TreeSelect } from 'ant-design-vue';
    import Pagination from "@/components/Pagination.vue";
    import { BatchTrackingPackage, BatchTrackingQueryParams, ApproveStatusResponseItem } from '../types';
    import useFetch from "@/hooks/useFetch";
    import { getSubscribedBu, getApproveStatus, getBatchTracking} from "@/API/approve";
    import { BuItem } from "@/views/Approve/SalesRoleApprove/ProgramHome/types";
    import useTableHeight from "@/hooks/useTableHeight";
    import { toThousands } from "@/utils/approve";
    const SHOW_PARENT = TreeSelect.SHOW_PARENT;
    const columns = [
        {
            title: 'BU',
            dataIndex: 'buName',
            key: 'buName',
            width: 80
        },
        {
            title: 'Batch Name',
            dataIndex: 'batchName',
            key: 'batchName',
            slots: { customRender: 'batchName' },
            width: 240,
            ellipsis: true

        },
        {
            title: 'PbP budget',
            dataIndex: 'pbpBudget',
            key: 'pbpBudget',
            slots: { customRender: 'pbpBudget' },
            width: 120,
            align: 'right'
        },
        {
            title: 'CBU budget',
            dataIndex: 'cbuBudget',
            key: 'cbuBudget',
            slots: { customRender: 'cbuBudget' },
            width: 120,
            align: 'right'
        },
        {
            title: 'Total budget',
            dataIndex: 'totalBudget',
            key: 'totalBudget',
            slots: { customRender: 'totalBudget' },
            width: 120,
            align: 'right'
        },
        // {
        //     title: 'Batch status',
        //     dataIndex: 'status',
        //     key: 'status',
        //     width: 160
        // },
        {
            title: 'Requestor',
            dataIndex: 'packageSaveActorId',
            key: 'packageSaveActorId',
            width: 120
        },
        {
            title: 'Submit date',
            dataIndex: 'packageSubmitTime',
            key: 'packageSubmitTime',
            slots: { customRender: 'submitDate' },
            width: 120
        },
        {
            title: 'Approval Date',
            dataIndex: 'packageApprovalDate',
            key: 'packageApprovalDate',
            width: 160
        },
        {
            title: 'Tracking',
            dataIndex: 'logDtoList',
            key: 'logDtoList',
            slots: { customRender: 'tracking' },
            width: 100,
            align: 'center'
        }
    ];
    interface TreeDataItem {
        value: string;
        key: string;
        title?: string;
        disabled?: boolean;
        children?: TreeDataItem[];
    }
    const qmOptions: TreeDataItem[] = [
        {
            title: 'Q1',
            value: 'Q1',
            key: 'Q1',
            children: [
                {
                    title: '01',
                    value: '01',
                    key: '01',
                },
                {
                    title: '02',
                    value: '02',
                    key: '02',
                },
                {
                    title: '03',
                    value: '03',
                    key: '03',
                },
            ],
        },
        {
            title: 'Q2',
            value: 'Q2',
            key: 'Q2',
            children: [
                {
                    title: '04',
                    value: '04',
                    key: '04',
                },
                {
                    title: '05',
                    value: '05',
                    key: '05',
                },
                {
                    title: '06',
                    value: '06',
                    key: '06',
                },
            ],
        },
        {
            title: 'Q3',
            value: 'Q3',
            key: 'Q3',
            children: [
                {
                    title: '07',
                    value: '07',
                    key: '07',
                },
                {
                    title: '08',
                    value: '08',
                    key: '08',
                },
                {
                    title: '09',
                    value: '09',
                    key: '09',
                },
            ],
        },
        {
            title: 'Q4',
            value: 'Q4',
            key: 'Q4',
            children: [
                {
                    title: '10',
                    value: '10',
                    key: '10',
                },
                {
                    title: '11',
                    value: '11',
                    key: '11',
                },
                {
                    title: '12',
                    value: '12',
                    key: '12',
                },
            ],
        },
    ];

    export default defineComponent({
        name: "BatchTracking",
        components: { Pagination },
        setup() {
            

            const { tableHeight } = useTableHeight();

            //Bu
            const { data: buOptions } = useFetch<BuItem[]>({}, [], getSubscribedBu);

            //Status
            const { data: packageStatus } = useFetch<ApproveStatusResponseItem[]>({ type: '1' }, [], getApproveStatus);


            // 年份
            const year = ref<string>(moment().add('month', 1).format('YYYY'));
            const openYear = ref<boolean>(false);
            const handlerOpenChange = (status: any): void =>{
                if (status) {
                    openYear.value = true;
                }
            }
            const handlerPanelChange = (value: string): void =>{
                const time = moment(value).format('YYYY');
                year.value = time as string;
                openYear.value = false;
            }

            // 季度 月份
            const getDefaultQm = (): string => {
                return 'Q' + moment().add('month', 1).quarter();
            }
            const qm = ref<string[]>([getDefaultQm()]);
            //分页事件处理
            const queryParams = reactive<BatchTrackingQueryParams>({
                bu: [],
                batchName: '',
                year: year.value,
                qm: qm.value,
                batchStatus: 'Approved'
            });

            const { data, pagination, refreshFetch } = useFetch<BatchTrackingPackage[]>(queryParams, [], getBatchTracking, true)


            const handlerPaginationChange = (page: number): void => {
                pagination.page = page;
                refreshFetch();
            }

            const handlerShowSizeChange = (current: number, pageSize: number): void => {
                pagination.pageSize = pageSize;
                Object.assign(pagination, { page: current, pageSize: pageSize });
                refreshFetch();
            }

            // const handlerPeriodChange = (date: string[]): void => {
            //     queryParams.from = date[0];
            //     queryParams.to = date[1];
            // }

            const handlerResetClick = (): void => {
                Object.assign(queryParams, {
                    bu: [],
                    batchName: '',
                    year: moment().add('month', 1).format('YYYY'),
                    qm: [getDefaultQm()],
                    batchStatus: ''
                })
                year.value = moment().add('month', 1).format('YYYY')
                qm.value = [getDefaultQm()]
                refreshFetch(1);

            }

            const handlerSearchClick = (): void => {
                Object.assign(queryParams, {year: year.value, qm: qm.value})
                refreshFetch(1);
            }

            return {
                //table
                columns,
                data,
                tableHeight,
                pagination,

                //select options
                buOptions,
                packageStatus,

                //queryParams
                ...toRefs(queryParams),

                //事件处理
                handlerPaginationChange,
                handlerShowSizeChange,
                // handlerPeriodChange,
                handlerResetClick,
                handlerSearchClick,

                //moment
                moment,

                //数字转换
                toThousands,

                //year q/m
                year,
                qm,
                openYear,
                handlerOpenChange,
                handlerPanelChange,
                qmOptions,
                SHOW_PARENT
            }
        }
    })
