
    import { defineComponent, ref, onMounted } from 'vue';
    import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router';
    import { useStore } from 'vuex';
    //pending approve
    import PendingApprove from "./components/PendingApprove.vue";
    //batch tracking`
    import BatchTracking from "./components/BatchTracking.vue";
    //program tracking
    import ProgramTracking from "./components/ProgramTracking.vue";

    export default defineComponent({
        name: "PaddingApprovePackage",
        components: {
            PendingApprove,
            BatchTracking,
            ProgramTracking
        },
        setup() {
            const router = useRouter();

            const route = useRoute();

            const { state } = useStore();

            const pendingApproveRef = ref();

            const selectPendingApprove = ref<number>(0);

            //当前的tab
            const currentTab = ref<string>('');

            const handlerTab = (tab: string): void => {
                router.push(`/mg/packageHome?tag=${tab}`);
            }

            const handlerApproveOrReject = (status: string): void => {
                //调用pendingApprove组件的handlerApprove方法
                pendingApproveRef.value.handlerApproveOrReject(status);
            }

            onMounted(() => {
                const tag: string = route.query.tag as string | null || 'pendingApprove';
                currentTab.value = tag;
            })

            onBeforeRouteUpdate((to, form, next) => {
                currentTab.value = to.query.tag as string || 'pendingApprove';
                next()
            })

            return {
                currentTab,
                handlerTab,
                handlerApproveOrReject,
                pendingApproveRef,
                selectPendingApprove,

                // store
                state
            }
        }
    })
