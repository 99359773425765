
    import { defineComponent, reactive, ref, onMounted } from 'vue';
    import { useStore } from 'vuex';
    import ApproveConfirmModal from "../../components/ApproveConfirmModal.vue";
    import moment from "moment";
    import { PendingApproveResponse, PendingApprovePackage, PackageApproveRequestBodyItem } from '../types';
    import { ColumnProps } from 'ant-design-vue/es/table/interface';
    import { getMgPendingApprove, approvePackage } from "@/API/approve";
    import useFetch from "@/hooks/useFetch";
    import { message } from "ant-design-vue";
    import { toThousands } from "@/utils/approve";

    type Key = ColumnProps['key'];

    const normalColumns = [
        {
            title: 'BU',
            dataIndex: 'buName',
            key: 'bu',
            width: 100
        },
        {
            title: 'Batch Name',
            dataIndex: 'batchName',
            key: 'batchName',
            slots: { customRender: 'batchName' },
            width: 240,
            ellipsis: true
        },
        {
            title: 'PbP budget',
            dataIndex: 'pbpBudget',
            key: 'pbpBudget',
            slots: { customRender: 'pbpBudget' },
            width: 160,
            align: 'right'
        },
        {
            title: 'CBU budget',
            dataIndex: 'cbuBudget',
            key: 'cbuBudget',
            slots: { customRender: 'cbuBudget' },
            width: 160,
            align: 'right'
        },
        {
            title: 'Total budget',
            dataIndex: 'totalBudget',
            key: 'totalBudget',
            slots: { customRender: 'totalBudget' },
            width: 160,
            align: 'right'
        },
        {
            title: 'Submit date',
            dataIndex: 'packageSubmitTime',
            key: 'submitDate',
            slots: { customRender: 'submitDate' },
            width: 160
        },
        {
            title: 'Tracking',
            dataIndex: 'logDtoList',
            key: 'logDtoList',
            slots: { customRender: 'tracking' },
            width: 100,
            align: 'center'
        }
    ]

    const fleetColumns = [
        {
            title: 'BU',
            dataIndex: 'buName',
            key: 'bu',
            width: 100
        },
        {
            title: 'Batch Approve Name',
            dataIndex: 'batchName',
            key: 'batchApproveName',
            slots: { customRender: 'batchName' },
            width: 240,
            ellipsis: true
        },
        {
            title: 'PbP budget',
            dataIndex: 'pbpBudget',
            key: 'pbpBudget',
            slots: { customRender: 'pbpBudget' },
            width: 160
        },
        {
            title: 'CBU budget',
            dataIndex: 'cbuBudget',
            key: 'cbuBudget',
            slots: { customRender: 'cbuBudget' },
            width: 160
        },
        {
            title: 'Total budget',
            dataIndex: 'totalBudget',
            key: 'totalBudget',
            slots: { customRender: 'totalBudget' },
            width: 160
        },
        {
            title: 'Submit date',
            dataIndex: 'packageSubmitTime',
            key: 'submitDate',
            slots: { customRender: 'submitDate' },
            width: 160
        },
        {
            title: 'Tracking',
            dataIndex: 'logDtoList',
            key: 'logDtoList',
            slots: { customRender: 'tracking' },
            width: 100,
            align: 'center'
        }
    ]
    export default defineComponent({
        name: "PaddingApprovePackage",
        props: {
            selectBatchNumber: {
                type: Number
            }
        },
        emits: ['update:selectBatchNumber'],
        components: {
            ApproveConfirmModal
        },
        setup(props, content) {
            const { commit } = useStore();

            //confirm 弹窗
            const visible = ref(false);

            //approveComment
            const approveComment = ref<string>('');

            //status
            const approveStatus = ref<string>('');

            const tableH = ref<number>(0);


            const rowSelection = reactive<{ selectedRowKeys: Key[]; selectedRowData: PendingApprovePackage[]; onChange: Function }>({
                onChange: (selectedRowKeys: Key[], selectedRows: PendingApprovePackage[]) => {
                    rowSelection.selectedRowKeys = selectedRowKeys;
                    rowSelection.selectedRowData = selectedRows;
                    content.emit('update:selectBatchNumber', selectedRowKeys.length);
                },
                selectedRowKeys: [],
                selectedRowData: []
            })
            const rowSelectionFleet = reactive<{ selectedRowKeys: Key[]; selectedRowData: PendingApprovePackage[]; onChange: Function }>({
                onChange: (selectedRowKeys: Key[], selectedRows: PendingApprovePackage[]) => {
                    rowSelectionFleet.selectedRowKeys = selectedRowKeys;
                    rowSelectionFleet.selectedRowData = selectedRows;
                    content.emit('update:selectBatchNumber', selectedRowKeys.length);
                },
                selectedRowKeys: [],
                selectedRowData: []
            })

            const resetTableSelectData = (): void => {
                rowSelection.selectedRowKeys = [];
                rowSelection.selectedRowData = [];
                rowSelectionFleet.selectedRowKeys = [];
                rowSelectionFleet.selectedRowData = [];
            }

            const { data, refreshFetch } = useFetch<PendingApproveResponse>({}, {
                fleetSales: [],
                normalSales: []
            }, getMgPendingApprove, false, (res: PendingApproveResponse): void => {
                // 存储PendingApproveCount
                commit('approve/updatePendingApproveCount', res.fleetSales.length + res.normalSales.length);
            });


            const handlerApproveOrReject = (status: string): void => {
                if (rowSelection.selectedRowKeys.length == 0 && rowSelectionFleet.selectedRowKeys.length === 0) {
                    message.error('请选择需要审批的batch');
                    return;
                }
                approveStatus.value = status;
                visible.value = !visible.value;
            }

            const handlerOk = (): void => {
                const requestBody: PackageApproveRequestBodyItem[] = [];
                rowSelection.selectedRowData.concat(rowSelectionFleet.selectedRowData).forEach(item => {
                    requestBody.push({
                        batchId: item.batchId,
                        approvalSatus: approveStatus.value,
                        content: approveComment.value
                    });
                })
                approvePackage(requestBody).then(data => {
                    visible.value = false;
                    refreshFetch();
                    resetTableSelectData();
                })
            }

            onMounted(() => {
                //132 是content所有的margin  40 * 2 = 80 是表格标题的高度
                const tableTheadHeight: number = (document.querySelector('.ant-table-thead') as HTMLDivElement).offsetHeight;
                tableH.value = ((document.querySelector('.content') as HTMLDivElement).offsetHeight - 132 - (tableTheadHeight * 2)) / 2;
            })

            return {
                /*table*/
                fleetColumns,
                normalColumns,
                rowSelection,
                rowSelectionFleet,
                data,

                //moment 格式化时间
                moment,

                //confirm modal
                visible,
                handlerApproveOrReject,

                //comment
                approveComment,

                //审批的状态
                approveStatus,

                //事件处理
                handlerOk,

                toThousands,

                tableH
            }
        }
    })
