
    import { defineComponent, computed } from 'vue';

    export default defineComponent({
        name: "ApprovalConfirmModal",
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            comment: {
                type: String
            },
            status: {
                type: String
            },
            //审批的batch项目
            data: {
                type: Array
            },
            okText: {
                type: String,
                default: 'Yes'
            },
            cancelText: {
                type: String,
                default: 'No'
            }
        },
        emits: ['update:visible', 'update:comment', 'on-cancel', 'on-ok'],
        setup(props) {
            const title = computed(() => props.status == 'approved' ? 'approved' : ( props.status == 'rejected' ? 'Reject' : 'Withdraw' ));
            return {
                title
            }
        }
    })
